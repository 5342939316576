import React from 'react'
import logo from '../assets/logo.svg'
import { BsArrowRight } from 'react-icons/bs'

function Navbar() {
  return (
    <div class='fixed top-0 flex w-full h-fit py-[2vh] px-[8.5vw]  z-[999]'>
        <div class='flex w-full h-fit justify-center sm:justify-between relative z-[999]'>
            <img src={logo} class='md:w-[5vh] w-[4vh]'/>

        </div>
    </div>
  )
}

export default Navbar