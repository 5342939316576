import React, { useState } from "react";
import "./App.css";
import Signup from "./Pages/Signup";
import SpaceWarp from "./Components/SpaceWarp"; // Import SpaceWarp
import Navbar from "./Components/Navbar";

function App() {
  
  return (
    <div className="w-full min-h-screen  relative">
      <Navbar/>
      <SpaceWarp />
      <div className="w-full h-screen absolute inset-0 flex items-center justify-center">
        <Signup />
      </div>
    </div>
  );
}

export default App;
