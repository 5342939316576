import React, { useEffect, useRef, useState } from 'react'
import {BsArrowRight} from 'react-icons/bs'
import {AiOutlineCheck, AiOutlineEye, AiOutlineEyeInvisible} from 'react-icons/ai'
import {TypeAnimation} from 'react-type-animation';
import { toBeRequired } from '@testing-library/jest-dom/matchers';

function Signup() {

    const [TypeCheck,setTypeCheck]=useState(true)
    const [Email,setEmail]=useState({content:'',check:false,error:false})
    const [Password,setPassword]=useState({content:'',check:false,show:false,error:false})
    const [Username,setUsername]=useState({content:'',check:false,error:false})
    const [Response,setResponse]=useState({content:'',check:false,error:false})


    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    function isValidPassword(pass){
        return /^(?=.*[0-9])(?=.*[a-z]).{8,}$/.test(pass) || /^.{15,}$/.test(pass)
    }

    function isValidUsername(user){
        return  /^[A-Za-z0-9]+(-[A-Za-z0-9]+)*$/.test(user)
    }

    function SubmitEmail(){
        if(!isValidEmail(Email.content)){
            setEmail({content:Email.content,check:false,error:true})
        }   
        else{
            setEmail({content:Email.content,check:true,error:false})
        }
    }

    function SubmitPassword(){
        if(!isValidPassword(Password.content)){
            setPassword({content:Password.content,check:false,show:Password.show,error:true})
        }
        else{
            setPassword({content:Password.content,check:true,show:Password.show,error:false})
        }
    }

    function SubmitUsername(){
        if(!isValidUsername(Username.content)){
            setUsername({content:Username.content,check:false,error:true})
        }
        else{
            setUsername({content:Username.content,check:true,error:false})
        }
    }

    function SubmitResponse(){
        if(Response.content=='y'||Response.content=='n'||Response.content=='Y'||Response.content=='N'){
            setResponse({content:Response.content,check:true,error:false})
        }
        else{
            setResponse({content:Response.content,check:false,error:true})
        }
    }

  const [componentHeight, setComponentHeight] = useState(0);
  const signupRef = useRef(null); // Create a ref to the Signup component

  useEffect(() => {
    // Function to update the componentHeight state with the height of the Signup component
    const updateComponentHeight = () => {
      if (signupRef.current) {
        localStorage.setItem('item',JSON.stringify(signupRef.current.offsetHeight))
        setComponentHeight(signupRef.current.offsetHeight);
      }
    };

    // Call the function initially and add an event listener for window resize
    updateComponentHeight();
    window.addEventListener('resize', updateComponentHeight);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateComponentHeight);
    };
  }, []);
  const divHeight = `${window.innerHeight*1.5}px`;

  return (
    <div ref={signupRef} class='w-full min-h-screen relative '>
        <div style={{ height: divHeight }} class='w-full absolute flex items-end bg-[#0c162d]/70 '>
        <div class='w-full h-[50vh] bg-gradient-to-br to-[#ffaba8]/30 to-99% from-transparent from-65%'></div>
        </div>
        <div class='w-full h-screen flex flex-col absolute top-0'>
            <div class='w-full h-1/2 flex'>
                <div class='w-1/2 h-full relative bg-gradient-to-br from-[#218bff]/30 from-1% to-transparent to-40% '>

                </div>
                <div class='w-1/2 h-full relative bg-gradient-to-tr to-[#218bff]/30 to-99% from-transparent from-65% '>

                </div>
            </div>
        </div>

        <div class='w-full h-screen flex flex-col pt-[20vh] justify-between items-center font-mono relative z-[999]'>
            <div class='flex flex-col space-y-[1vh] justify-between items-center'>
                <div class='lg:w-[45vw] w-[85vw] flex flex-col text-white px-[3vw] py-[3vw] bg-[#0c162d] space-y-[3vh] border-[1px] border-blue-200/20 rounded-lg'>
                        {
                            TypeCheck==true?
                                <TypeAnimation
                                    sequence={[
                                        "Welcome to Playe! \n Let's begin the adventure",
                                        ()=>{setTypeCheck(false)}
                                    ]}
                                    className='whitespace-pre-line text-blue-200/60' // Optional: Hide the cursor when animation is complete
                                />
                            :
                                <div class='flex flex-col'>
                                    <text class='text-blue-200/60'>Welcome to Playe!</text>
                                    <text class='text-blue-200/60'>Let's begin the adventure</text>
                                </div>
                        }
                    
                    {
                        TypeCheck==false?
                        <div class=' flex flex-col space-y-[1vh]'>
                            <text class='text-[#34d2ca]'>Enter your Email*</text>
                            <div class='w-full flex sm:flex-row flex-col space-y-[1vh] items-center'>
                                <div class='w-full flex items-center space-x-[1vh]'>
                                    {
                                        Email.check==true?
                                        <AiOutlineCheck color='green'/>
                                        :
                                        <BsArrowRight color='#c32f98'/>
                                    }
                                    <input type='text' class='w-full bg-transparent ' onChange={(e)=>{setEmail({content:e.target.value,check:Email.check})}}></input>
                                </div>
                                <button class='sm:w-fit w-full text-blue-200/60 border-[1px] border-blue-200/60 px-[1vw] py-[1vh] rounded-lg text-sm' onClick={SubmitEmail}>Continue</button>
                            </div>
                            {
                                Email.error==true?
                                <text class='text-[0.8rem] font-light text-blue-200/60'>Email is Invalid</text>
                                :
                                <></>
                            }
                        </div>
                        :
                        <></>
                    }
                    {
                        Email.check==true?
                        <div class=' flex flex-col'>
                            <text class='text-[#34d2ca]'>Create a Password*</text>
                            <div class='w-full flex sm:flex-row flex-col space-y-[1vh] items-center'>
                                <div class=' w-full flex items-center space-x-[1vh]'>
                                    {
                                        Password.check==true?
                                        <AiOutlineCheck color='green'/>
                                        :
                                        <BsArrowRight color='#c32f98'/>
                                    }
                                    <div class='w-full flex items-center'>
                                        <input onChange={(e)=>{setPassword({content:e.target.value,check:Password.check,show:Password.show})}} type={Password.show==true?'text':'password'} class='w-full bg-transparent outline-blue '></input>
                                        {
                                            Password.show==false?
                                            <AiOutlineEye size='1.3rem' color='#bfdbff' class='cursor-pointer' onClick={()=>{setPassword({content:Password.content,check:Password.check,show:!Password.show})}}/>
                                            :
                                            <AiOutlineEyeInvisible size='1.3rem' color='#bfdbff' class='cursor-pointer' onClick={()=>{setPassword({content:Password.content,check:Password.check,show:!Password.show})}}/>
                                        }
                                        
                                    </div>
                                </div>
                                <button onClick={SubmitPassword} class='sm:w-fit w-full text-blue-200/60 border-[1px] border-blue-200/60 px-[1vw] py-[1vh] rounded-lg text-sm'>Continue</button>
                                
                            </div>
                            {
                                Password.error==true?
                                <text class='text-[0.8rem] font-light text-blue-200/60'>Make sure it's at least 15 characters OR at least 8 characters including a number and a lowercase letter.</text>
                                :
                                <></>
                            }
                        </div>
                        :
                        <></>
                    }
                    {
                        Password.check==true && Email.check==true?
                        <div class=' flex flex-col'>
                            <text class='text-[#34d2ca]'>Enter a Username*</text>
                            <div class='w-full flex sm:flex-row flex-col space-y-[1vh] items-center'>
                                <div class='w-full flex items-center space-x-[1vh]'>
                                    {
                                        Username.check==true?
                                        <AiOutlineCheck color='green'/>
                                        :
                                        <BsArrowRight color='#c32f98'/>
                                    }
                                    <input onChange={(e)=>{setUsername({content:e.target.value,check:Username.check})}} type='text' class='w-full bg-transparent outline-blue '></input>
                                </div>
                                <button onClick={SubmitUsername} class='sm:w-fit w-full text-blue-200/60 border-[1px] border-blue-200/60 px-[1vw] py-[1vh] rounded-lg text-sm'>Continue</button>
                            </div>
                            {
                                Username.error==true?
                                <text class='text-[0.8rem] font-light text-blue-200/60'>Username may only contain alphanumeric characters or single hyphens, and cannot begin or end with a hyphen.</text>
                                :
                                <></>
                            }
                        </div>
                        :
                        <></>
                    }
                    {
                        Password.check==true && Email.check==true && Username.check==true?
                        <div class=' flex flex-col'>
                            <text class='text-[#34d2ca]'>Would you like to receive product updates and announcements via email?</text>
                            <text class='text-[#34d2ca]'>Type "y" for yes or "n" for no</text>
                            <div class='w-full flex sm:flex-row flex-col space-y-[1vh] items-center'>
                                <div class='w-full flex items-center space-x-[1vh]'>
                                    {
                                        Response.check==true?
                                        <AiOutlineCheck color='green'/>
                                        :
                                        <BsArrowRight color='#c32f98'/>
                                    }
                                    <input onChange={(e)=>{setResponse({content:e.target.value,check:Response.check})}} type='text' class='w-full bg-transparent outline-blue'></input>
                                </div>
                                <button onClick={SubmitResponse} class='sm:w-fit w-full text-blue-200/60 border-[1px] border-blue-200/60 px-[1vw] py-[1vh] rounded-lg text-sm'>Continue</button>
                            </div>
                            {
                                Response.error==true?
                                <text class='text-[0.8rem] font-light text-blue-200/60'>Invalid choice.</text>
                                :
                                <></>
                            }
                        </div>
                        :
                        <></>
                    }
                    {
                        TypeCheck==false&&Email.check==true&&Password.check==true&&Username.check==true&&Response.check==true?
                        <button class='w-full py-[2vh] text-center border-[1px] rounded-lg border-green-700 text-green-700'>Create Account</button>
                        :
                        <></>
                    }
                </div>
                <div class='lg:w-[45vw] w-[85vw] flex items-center sm:text-base text-sm md:text-lg md:space-x-[1vw] space-x-[3vw] z-[999] '>
                    <text class='text-blue-200/60'>Already have an account?</text>
                    <button class='flex items-center md:space-x-[0.2vw]'>
                        <text class='text-white'>Sign in</text>
                        <BsArrowRight color='white' size='0.8rem'/>
                    </button>
                </div>
            </div>
            <div class='w-full h-[40vh] pb-[8vh] flex justify-center items-center  '>
                <text class='lg:w-[45vw] w-[85vw] text-blue-200/60 text-xs font-sans'>By creating an account, you agree to the <text class='text-blue-600 underline'>Terms of Service</text>. For more information about <text class='text-blue-600 underline' >Playe's privacy practices</text>, see the Playe Privacy Statement. We'll occasionally send you account-related emails.</text>
            </div>
            
        </div>
    </div>
  )
}

export default Signup