import React, { useEffect, useState } from 'react';
import * as THREE from 'three';

import starTexture from '../assets/star.png'; // Replace with the correct path to your texture

function SpaceWarp() {
  const [scrolledHeight, setScrolledHeight] = useState(0);
  useEffect(() => {
    let scene, camera, renderer, starGeo, starMaterial, stars;

    // Create a function for initialization
    function init() {
      // Create scene object
      scene = new THREE.Scene();

      // Setup camera
      camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 1000);
      camera.position.z = 1;

      // Setup renderer
      renderer = new THREE.WebGLRenderer();
      renderer.setSize(window.innerWidth, window.innerHeight*1.5);
      document.getElementById('Space-container').appendChild(renderer.domElement);

      starGeo = new THREE.BufferGeometry(); // Use BufferGeometry for better performance

      const starVertices = [];
      const velocity = [];
      const acceleration = [];
      for (var i = 0; i < 6000; i++) {
        const star = new THREE.Vector3(
          Math.random() * 600 - 300,
          Math.random() * 600 - 300,
          Math.random() * 600 - 300
        );
        starVertices.push(star.x, star.y, star.z);
        velocity.push(0, 0, 0);
        acceleration.push(0.1, 0.1, 0.1);
      }

      starGeo.setAttribute('position', new THREE.Float32BufferAttribute(starVertices, 3));
      starGeo.velocity = new THREE.Float32BufferAttribute(velocity, 3);
      starGeo.acceleration = new THREE.Float32BufferAttribute(acceleration, 3);

      const sprite = new THREE.TextureLoader().load(starTexture);
      starMaterial = new THREE.PointsMaterial({
        color: 0xffffff,
        size: 0.7,
        map: sprite,
      });

      stars = new THREE.Points(starGeo, starMaterial);
      scene.add(stars);

      animate();
    }

    // Function to update star positions based on time
    function updateStarPositions() {
      const time = Date.now() * 0.0001; // Adjust the multiplier to control the speed of change
      const positions = starGeo.getAttribute('position').array;
      const vel = starGeo.velocity.array;
      const acc = starGeo.acceleration.array;

      for (let i = 0; i < positions.length; i += 3) {
        positions[i + 1] -= acc[i + 1]; // Adjust the equation as needed
        if (positions[i + 1] < -200) {
          positions[i + 1] = 200;
        }
      }

      starGeo.getAttribute('position').needsUpdate = true;
    }

    // Rendering loop
    function animate() {
      updateStarPositions(); // Update star positions based on time
      renderer.render(scene, camera);
      requestAnimationFrame(animate);
    }

    function onWindowResize() {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    }

  
    function onScroll() {
      const newScrolledHeight = window.scrollY;
      setScrolledHeight(newScrolledHeight);

      const newCameraHeight = window.innerHeight + newScrolledHeight;

      camera.aspect = window.innerWidth / newCameraHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, newCameraHeight);
    }


    // Initialize the Three.js scene
    init();
  }, []);

  return <div id='Space-container' className='blur-[1.5px] -z-10'></div>;
}

export default SpaceWarp;
